.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    backdrop-filter: blur(4px);
}
.popup-inner {
    position: relative;
    padding: 3px;
    width: 95%;
    max-width: 640px;
    background-color: #0000008f;
    border-radius: 4px;
    animation: boom 0.5s ease forwards;
    height: 108px;
}

@keyframes boom {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

#colse-btn{
    position: absolute;    
    right: 3px;
    border-radius: 4px;
    border: 0;
    top: 2px;
}
#error-login{
    font-size: 21px;
    color: white;
}

