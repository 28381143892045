.main-start {
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

}
.main-new-start {
  width: 100%;
  height: 100%;
  background: #D6D9E2;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

}

.main-start::-webkit-scrollbar {
  display: none;
}

::placeholder {
  font-size: 16px;
  font-family: Roboto serif;
  font-weight: bold;
}

.form-control {
  background-color: #D6D9E2;
}

.fixed-top-centered {
  width: 100%;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-section {
  height: 60%;
}

.login-top-section {
  height: 50%;
  display: grid;
}

.top-section-inner,
.buttom-section-inner {
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center
}

#login-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #3F3F3F;

}

#login-title-sub {
  font-size: 12px;
  font-weight: bold;

  color: #3F3F3F;
}

.button {
  font-size: 14px;
  font-weight: 600;
  background-color: #ED3237;
  color: white;
  border-radius: 5px;
  border: #ED3237;
  padding: 5px;
}

.button>a {
  font-size: 17px;
  font-weight: 600;
  text-decoration: auto;
  color: white;
}

div>#google,
#facebook {
  padding: 4px 20px;
  border-radius: 50px;
  background: #3F3F3F;
  margin: 12px;
  color: white;
}

#fa-icon {
  height: 16px;
}

.main-start>div.form-container {
  width: 100%;
  max-width: 420px;
}

#dom {
  display: none;
}

#sub-btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.input-group {
  margin-bottom: 16px;
}


.signup>label {
  font-size: 16px;
  font-weight: bold;
}

.fa-spinner {
  display: none;
}

#area-sel {
  display: none;
}

select.form-control:not([size]):not([multiple]),
input {
  height: 3rem;
}

.header-text {
  color: white;
}

.form-check-label {
  color: white;
}

.main-start>div.form-container {
  padding: 0 1rem;
}

@media only screen and (min-width: 600px) {
  .form-container {
    background: white;
  }

  .header-text {
    color: black !important;
  }

  #sub-btn {
    background-color: #ED3237;
  }

  .signup>label {
    color: black;
  }

  .form-check-label,
  .text-white {
    color: black !important;
  }
}

.form-check-input {
  margin-top: -0.9rem !important;
}

#otp-container {
  position: fixed;
  background: #231e1eab;
  width: 100%;
  height: 100%;
  z-index: 1111;
  display: none;
}

.otp-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.otp-form {
  width: 320px;
  background: white;
  border-radius: 8px;
  padding: 24px 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp-form p {
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
}

.otp-form input {
  font-size: 1.6rem;
  letter-spacing: 6px;
  font-weight: bold;
  width: 150px;
  text-align: center;
  border: 1px solid;
  border-radius: 4px;
}

input::-webkit-date-and-time-value {
  text-align: left;
  padding: .575rem .75rem;
}

.form-control:focus {
  box-shadow: none;
  border-color: #e7e7e7;
  background-color: #fff;
}

#telephone,
#more,#homeImg {
   border: 1px solid;
    height: 44px;
    display: block;
    border-radius: 34px;
    padding: 8px;
    width: 44px;
   
}
#arrow
{
  border: 1px solid;
    height: 44px;
    display: block;
    border-radius: 34px;
    padding: 8px;
    width: 44px;
   
}

#hamBurger {
  border: 1.5px solid;
  border-radius: 50px;
  padding: 12px;
}

#consultant-img>div>img {
  height: 55px;
  display: block;
  width: 55px;
  border: 3px solid #ff403c3b;
  border-radius: 50px;
}

.carousel-indicators li {
  width: 5px !important;
  border-radius: 13px;
  height: 5px !important;
  padding: 6px;
  background-color: grey;
}

.carousel-indicators .active {
  background-color: #0f0e0e;
}

.carousel-indicators {
  bottom: -31px;
}

#option-menu>div {
  border-radius: 12px;
  padding: 2px 10px;
  margin: 2px;
  background-color: #D9D9D9;
  
  
}
#option-menu{
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

div>#active {

  background-color: #3F3F3F;
  color: white;
}

#option-menu>div>span {
  font-size: 12px;
  font-family: Roboto serif;
  font-weight: bold;
  width: max-content;
  display: block;
}

.carousel-inner {
  border-radius: 15px;
}

.carousel-item.active {
  height: 160px;
}
.aboutus>span{
    font-size: 43px;
    letter-spacing: 21px;
    text-align: center;
}
.aboutus01>span,#innerAbout{
    font-size: 21px;
    font-family: lato, sans-serif;
    font-weight: 700;
}
.about-content>span{
    font-size: 13px;
    font-family: sans-serif;
  
}
.Earn-point{
  background-color:#404040;
  height: 132px;
  border-radius: 10px;
}
.Play-point{
  background-color: #40404073;
  height: 180px;
  border-radius: 12px;
  width: 40%;
}
.Play-point>div>img{
  height: auto;
  width: 100px;
}
.play-btn{
  background: red;
  color: white;
  border-radius: 15px;
  border: 0;
  font-size: 16px;
  padding: 4px 7px;
}
.play-text{
  color: white;
  font-size: 15px;
}
.ticket-invite-btn{
    width: 86px;
    border: 1px solid;
    padding: 5px;
    border-radius: 50px;
    background-color: #3D3D3D;
    color: white;
    text-align: center;
    font-family: lato, sans-serif;
    font-weight: 600;
}
.invite-friend-box{
  height: 433px;
  border-radius: 10px;
  background-color: #F5F5F5;
  width: 90%;
}
.invite-friend-box2{
  height: 50px;
  border-radius: 10px;
  background-color: #F5F5F5;
  width: 90%;
}
.refer-text>span{
  font-size: 26px;
  font-family: lato,sans-serif;
  color: #FF403C;
}
.share-text>span{
    font-size: 13px;
    font-family: lato, sans-serif;
    font-weight: 800;
}
.copy-btn{
    margin: 7px;
    height: 28px;
    border-radius: 25px;
    background: #3d3d3d;
    color: white;
    border: 0;
}
.share-input{
  border: 1px solid;
  border-radius: 50px;
  background-color: white;
}
.share-input>input{
    border: 0;
    width: 100%;
    border-radius: 25px;
    padding: 14px;
}
.share-icon>a>span>img{
  height: 40px;
  padding: 0px 8px;
}
.invite-friend-box2,.p-2{
    font-size: 10px;
    font-family: lato, sans-serif;
    font-weight: 800;
}
.invite-friend-box2>div>span{
  font-family: lato, sans-serif;
}
.term-condition>span{
    font-family: lato, sans-serif;
    font-weight: 600;
    border-bottom: 1px solid;
    padding: 1px;
}
.invite-box{
  height: 80px;
  border-radius: 10px;
  background-color: #F5F5F5;
  width: 90%;
}
.Recent-invite-box{
  height: 80px;
  border-radius: 10px;
  background-color: #F5F5F5;
  width: 47%;
}
.recent-invite>span{
    font-size: 15px;
    font-weight: 600;
    font-family: lato, sans-serif;
}
.invite-name-text{
    font-size: 12px;
    font-weight: 600;
    font-family: lato, sans-serif;
}
.points-log{
  font-size: 25px;
  color: #ff403c;
  font-weight: 600;
  margin-left: 17px;
}
.point-text{
 color:#898282
}
.consultant-text{
  font-size: 14px;
  font-family: lato;
  font-weight: 600;
}
.consultant-text-schedule{
  font-size: 16px;
  font-family: lato;
}
.tax-calculator-text{
  font-size: 16px;
  font-family: lato;
}
.consultant-text1>span{
  font-size: 32px;
  font-family: lato, sans-serif;
  font-weight: 600;
}
.tax-text>span{
  font-size: 32px;
  font-family: lato, sans-serif;
  font-weight: 600;
}
.consult-btn,.tax-btn{
  border: 1px solid;
  border-radius: 39px;
  padding: 12px;
  width: 50%;
  background-color: #3F3F3F;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border: 0;
  font-family: lato, sans-serif;
}
.meeting-textarea{
    padding: 12px;
    width: 100%;
    border-radius: 13px;
    border: 0;
}
.contact-textarea{
  padding: 12px;
  width: 100%;
  border-radius: 7px;
  border: 0;
  background-color: #D6D9E2;
}
.date,.time,.meeting{
  border-radius: 50px;
  background-color: #3F3F3F;
  color: white;
}
.input-field{
  width: 215px;
  border-radius: 50px;
 
}
.lable-meeting,.lable-date,.lable-time{
    font-size: 17px;
    font-family: lato;
    font-weight: 600;
    padding: 4px;
}
.thankyou-text>span{
  font-size: 32px;
  font-family: lato, sans-serif;
  font-weight: 600;
}
.contact-thankyou-text{
  font-size: 19px !important;
  font-family: lato, sans-serif !important;
  font-weight: 600 !important;
}


.container .arrow {
  width: 30px;
  height: 20px;
  overflow: hidden;
  position: relative;
  top: 128px;
  rotate: -90deg;
  margin-left: 13rem;
}

.container .arrow .outer {
  width: 0;
  height: 0;
  border-right: 20px solid #000000;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.container .arrow .inner {
  width: 0;
  height: 0;
  border-right: 20px solid #ffffff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 2px;
}

.container .message-body {
  float: left;
  width: 233px;
  height: auto;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 6px 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  height: 114px;
  padding: 13px;
}

.container .message-body p {
  margin: 0;
}
.tax-do-know{
  font-size: 16px;
  font-family: lato, sans-serif;
  font-weight: 600;

}
.profile-text{
  font-size: 24px;
  font-weight: 700;
}
.logo-profile{
    width: 30%;
    border: 2px solid;
    border-radius: 58px;
}
.tab>span{
  font-size: 14px;
  font-weight: bold;
  font-family: lato, sans-serif;
}
.tab>.active,#Tab0{
  border-bottom: 3px solid #ff403c;
}
.profile-info>p,strong{
  font-size: 15px !important;
}
.update-password-form,.update-form,.profile-details{
  display: none;
}
#update-password,#update-profile,#details-profile{
  display: block;
}
img.push-logo{
  height: 68px;
  width:auto;
}
#camera{
    height: 20px;
    margin-top: 84px;
    margin-left: -40px;
    background: #ff4b4729;
    border-radius: 10px;
}
.remove-scroll{
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}
.hide-stories{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background: #000000cf;
    z-index: 111;
   
}
.show-stories{
  display: none;
}
#show-profile{
  display: block;
}
#hide-profile{
  display: none;
}
.video-section-show>div>span{
  color: white;
}
.video-section-show>div>.next{
   color: white;
    font-size: 13px;
    border: 1px solid;
    padding: 4px;
    border-radius: 10px;

}
/*/////////////////////// Quiz  //////////////////////*/

.quiz-app .quiz-info {
  display: flex;
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 20px;
}

.quiz-app .quiz-info .category {
  flex: 1;
}

.quiz-app .quiz-info .count {
  flex: 1;
  text-align: right;
}

.quiz-app .quiz-area {
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.quiz-app .quiz-area h2 {
  margin: 0;
}

.quiz-app .options-area {
  background-color: #fff;
  padding: 0 20px;
  /* border-radius: 20px; */
}

.quiz-app .options-area .option {
  background-color: #f6f6f6;
  padding: 9px;
  margin: 10px;
  border-radius: 20px;
}

.quiz-app .options-area .option:hover {
  background-color: #f0f0f0;
}

/* .quiz-app .options-area .option:not(:last-child) {
  border-bottom: 1px solid #dfdfdf;
} */

.quiz-app .options-area .option input[type="Radio"]:checked + label {
  color: #0055ff;
}

.quiz-app .options-area .option label {
  cursor: pointer;
  color: #444;
  font-weight: bold;
  margin-left: 5px;
  position: relative;
  top: -16px;
}

.quiz-app .submit-button {
  background-color: #3f3f3f;
  display: block;
  width: 84%;
  cursor: pointer;
  padding: 9px;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border-radius: 20px;
  margin: 8px auto;
}

.quiz-app .submit-button:hover {
  background-color: #3f3f3f;
}

.quiz-app .submit-button:focus {
  outline: none;
}

.quiz-app .bullets {
  background-color: #f6f6f6;
  display: flex;
  padding: 9px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.quiz-app .bullets .spans {
  flex: 1;
  display: flex;
}

.quiz-app .bullets .spans span {
  width: 20px;
  height: 20px;
  background-color: #fff;
  margin-right: 10px;
  border-radius: 50%;
}

.quiz-app .bullets .spans span.on {
  background-color: #3f3f3f;
}

.quiz-app .results {
  text-align: center;
  font-size: 24px;
  margin-top: 10rem;
}

/*/////////////////////////////////////////////*/
@media (min-width: 200px) {
  .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 48%;
      margin: 3px;
  }
}
.card {
  border-radius: 8px;
  height: 100px;
  width: 46%;
  margin: 2%;

}
.card-title{
  font-size: 15px;
}
.service-box{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.services-box>.active{
  background-color: red;  
}
#show-res>ul>li{
  font-size: 15px;
  margin: 10px;
}
#show-res>p{
  font-size: 15px;
  margin: 10px;
}
#webpack-dev-server-client-overlay{
  display: none;
}
.card-body{
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-body2 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}
#email-box,#otp-box-show,#password-box-show{
  display: none;
}
.quiz-app .results {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  animation: popUp 0.6s ease-in-out;
}
.quiz-app .results span.bad {
  color: #ff4d4d;
  font-size: 2rem;
  font-weight: bold;
  display: block;
  margin-top: 10px;
  animation: shake 0.8s ease-in-out infinite;
}
.quiz-app .results span.good {
  color: #28a745;
  font-size: 2rem;
  font-weight: bold;
  display: block;
  margin-top: 10px;
  animation: bounce 0.8s ease-in-out infinite;
}
.emoji {
  font-size: 3rem;
  margin: 10px 0;
}
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes popUp {
  0% { transform: scale(0.5); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}