
*, html, body {
    box-sizing: border-box;
    font-family: "Roboto Serif", serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.4;
    font-optical-sizing: auto;
}

#av_loader{
    display:none;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: rgba(255,255,255,.5);
    backdrop-filter: blur(1px);
    z-index: 1;
}
#av_loader_inner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.6rem;
}
html, body{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 700;
}
html{
    scroll-behavior: smooth;
}
body::-webkit-scrollbar {
    width: 1em;
}
label{
    margin-bottom: 5px;
}
.form-control{
    padding:0.575rem .75rem
}
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.heading{
    font-family: 'Pathway Gothic One', sans-serif !important;
    font-weight: 400;
}
:root{
    --primary-color: #ED3237;
    --secondary-color: #003366;
    --text-color: white;
    --grey: #f4f4f4;
    --bg:  white;
    --footer-height: 58px;
}

.push-btn{
    position: fixed;
    bottom: var(--footer-height);
    background: white;
    color: #4D4D50;
    box-shadow: 0px 0px 8px 0 #626262b8;
    font-size: 17px;
    margin: 0.6rem;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding-left: 4px;
    justify-content: center;
    display: none;
    align-items: center;
    cursor: pointer;
}
.js-push-btn{
    border-radius: 4px !important;
}
.push-btn > i.icon {
    width: 15px;
    height: 22px;
}
#push-subscribe-container{
    
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 111;
}

#push-subscribe-body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
#push-subscribe-body>div{
    background: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 1px 1px 12px 4px #3e3e3ed1;
    margin: 1rem;
    max-width: 380px;
}
/* a{
    color: white !important;
    
} */
.btn-primary{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.btn-transparent,.btn-transparent:hover,.btn-transparent:focus{
    background-color: none;
    border: none;
    outline: none;
    color: var(--primary-color) !important;
    font-weight: 700;
}
body{
    background-color: white;
}
main{
    background-color: var(--bg);
    width: 100%;
    height: calc(100% - var(--footer-height));
    overflow: auto;
}
.main-container{
    background-color: var(--bg);
    display: flex;
    flex-direction: column;
    padding: 2rem 0.6rem;
}
.hamburger{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0 1rem;
    font-size: 1.4rem;
}
label.required::after{
    content: '*';
    color: red;
}
select option:checked {
    background-color: var(--primary-color); /* Change this to your desired background color */
    color: #fff; /* Optional: Change text color for better visibility */
}
footer{
    padding: 4px 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color:#ED3237;
    height: var(--footer-height);
}
footer a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    border-radius: 50%;
    color: #2d2d2d;
    width: 32px;
    height: 32px;
}
.btn-primary:hover {
    color: #fff;
    background-color: var(--primary-color);
    border-color: unset;
}
footer a.active, footer a:focus, footer a:active{
    background-color: #ffffff;
    color: rgb(0, 0, 0) !important;
}   
footer span{
    font-size: 0.72rem;
    margin-top: 2px;
    font-weight: bold;
}

h1, h2, h3, h4, h5 {
    margin: 0.5rem;
}
/* COMMON CSS BEGINS */

    .text-white{color: white;}
    .text-bold{font-weight: bold;}
    .bg-white{background-color: white;}

    .my-1{ 
        margin-top: 0.5rem !important;  
        margin-bottom: 0.5rem !important;
        
    }
    .my-2{ 
        margin-top: 1rem !important;    
        margin-bottom: 1rem !important;
    }
    .my-3{ 
        margin-top: 1.5rem !important;    
        margin-bottom: 1.5rem !important;
    }
    .my-4{ 
        margin-top: 2rem !important;    
        margin-bottom: 2rem !important;
    }

    .mx-1{ margin-left: 0.5rem !important;  margin-right: 0.5rem !important;}
    .mx-2{ margin-left: 1rem !important;    margin-right: 1rem !important;}
    .mx-3{ margin-left: 1.5rem !important;    margin-right: 1.5rem !important;}
    .mx-4{ margin-left: 2rem !important;    margin-right: 2rem !important;}

    .mt-1{margin-top: 0.5rem !important;}
    .mt-2{margin-top: 1rem !important;}
    .mt-3{margin-top: 1.5rem !important;}
    .mt-4{margin-top: 2rem !important;}
    .mt-5{margin-top: 2.5rem !important;}
    .mt-6{margin-top: 3rem !important;}

    .mb-1{margin-bottom: 0.5rem !important;}
    .mb-2{margin-bottom: 1rem !important;}
    .mb-3{margin-bottom: 1.5rem !important;}
    .mb-4{margin-bottom: 2rem !important;}

    .btn{
        border-radius: 25px;
        font-weight: 600;
    }
    .btn-transparent{
        background: transparent;
        color: var(--bg);
       border: 2px solid white;
    }
    .btn-link{
        background-color: white;
        padding: 2px 8px;
        color: black;
        border-radius: 15px;
        margin: 8px;
        box-shadow: 1px 1px 5px;
        font-weight: bold;
        font-size: 0.9rem;
    }
    .hide{
        display: none !important;
    }

    .jconfirm-icon-c svg{
        font-size: 26px;
    }
    @media only screen and (max-width: 767px){
        .ui.search.dropdown .menu {
            max-height: 16rem;
        }
    }
/* COMMON CSS ENDS */
.points-tbl tr{
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}
.points-tbl tr:nth-child(even) {
    background-color: #eaeaea;
}
.points-tbl td,.points-tbl th{
    padding: 0.4rem 0.6rem;
    font-size: 0.6rem;
}
table.points-tbl{
    width: 95%;
    background: var(--grey);
    margin: 0.5rem;
    border-radius: 8px;
}
table.points-tbl thead{
    color: black;
    font-weight: bold;
    font-size: 1rem;
}
table.points-tbl tbody{
    background-color: white;
}
#coolie-details{
    box-shadow: 0px 0px 5px 2px #8080805c;
    border-radius: 10px;
    margin: 0px 0px 3px;
    padding: 0 4px;
}
.btn-group-sm{
    border-radius: 3px;
}
.box{
    margin: 4px 0;
}
.heading-result{
    padding: 2px 0;
    color: grey;
    
}
span.heading-show {
font-size: 90%;
color: black;
}


#menu-header{
    color: #ffffff;
    font-size: 16px;
}
#ans{
    font-size: 11px;
    color: #0000008a;
    padding: 0 15px 0px;
    text-align: justify;
    font-family: sans-serif;
}
#heading{
    font-size: 14px;
    font-family: Roboto serif;
    font-weight: bold;
    width: 100%;
    background:#DBDBDB;
    border-radius: 50px;
    padding: 5px;
}




